import { useCallback, useState, memo } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { debounce } from 'lodash';

type Props = {
  latitute: number;
  longitude: number;
  onChangeLocation: (props: { latitute: number; longitude: number }) => void;
  options?: google.maps.MapOptions;
};

function GoogleMapsPicker({
  latitute,
  longitude,
  onChangeLocation,
  options,
}: Props) {
  const center = { lat: latitute, lng: longitude };

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((_: google.maps.Map) => {
    setMap(null);
  }, []);

  const handleIdle = debounce(() => {
    if (!map) return;
    const c = map.getCenter();
    if (!c) return;
    onChangeLocation({ latitute: c.lat(), longitude: c.lng() });
  }, 1000);

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '262px' }}
      center={center}
      zoom={16}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onIdle={handleIdle}
      options={{
        fullscreenControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
        ...options,
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <div
        className="absolute"
        style={{ top: 'calc(50% - 46px)', left: 'calc(50% - 16px)' }}
      >
        <img
          src="/marker.png"
          width={32}
          height={46}
          alt="marker"
          draggable={false}
        />
      </div>
    </GoogleMap>
  );
}

export default memo(GoogleMapsPicker);
