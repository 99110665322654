export const extractCleanAddress = (
  formattedAddress: string | undefined,
): string => {
  if (!formattedAddress) return '';
  let address = formattedAddress.trim();

  // 「日本、」や「日本,」などを先頭から除去
  address = address.replace(/^日本[、,]?\s*/, '');

  // 郵便番号（〒123-4567 もしくは 123-4567）が先頭にあれば除去
  address = address.replace(/^〒?\d{3}-\d{4}\s*/, '');

  // 郵便番号が末尾にある場合（日本語住所の後にスペースやカンマで繋がれている）
  address = address.replace(/\s*〒?\d{3}-\d{4}$/, '');

  // 「日本」が末尾にある場合も除去（例: ...東京都 104-0061 日本）
  address = address.replace(/\s*日本$/, '');

  return address.trim();
};
