import { closeWindow } from '@pocketsign/in-app-sdk';
import { useNavigate } from 'react-router-dom';
import { sdk } from '../../lib/sdk';
import { useEffect, useState } from 'react';
import { useQueryGetTenantMessage } from '../../hooks/useQuery';
import { STORAGE_KEY } from '../../constants/storage';

const Header = ({
  title,
  isShowBackArrow,
  isTransactionPage,
  isCouponDetailPage,
}: {
  title?: string;
  isShowBackArrow: boolean;
  isTransactionPage?: boolean; // FIXME: こういったことをやるのなら最初からボタン押下のハンドラを用意すべき
  isCouponDetailPage?: boolean; // FIXME: これに関わらずhistoryがなければnavigate(-1)しなければよい
}) => {
  const navigate = useNavigate();
  const [savedCouponID, setSavedCouponID] = useState<string | null>(null);
  const { data: getTenantMessage } = useQueryGetTenantMessage();

  useEffect(() => {
    if (isTransactionPage) {
      const couponID = sessionStorage.getItem(STORAGE_KEY.COUPON_ID);
      setSavedCouponID(couponID);
    }
  }, []);

  // FIXME: 高さを明示的に指定しないでほしい
  return (
    <>
      <header
        className={`z-100 fixed box-border t-0 l-0 w-full bg-#FAF6EF ${getTenantMessage?.message ? 'h-76px' : 'h-48px'}`}
      >
        {getTenantMessage?.message && (
          <div
            className="h-20px py-4px px-12px text-#FFF font-semibold text-center bg-#F39828"
            style={{
              whiteSpace: 'nowrap', // コンテンツを改行せずに一行で表示
              overflowX: 'auto', // 横スクロールを有効にする
            }}
          >
            {getTenantMessage?.message}
          </div>
        )}
        <div className="flex justify-between box-border w-full h-48px py-12px px-20px">
          {isShowBackArrow ? (
            <div
              onClick={() => {
                if (isTransactionPage) {
                  sessionStorage.removeItem(STORAGE_KEY.COUPON_ID);
                  navigate(`/coupons/${savedCouponID}`);
                } else if (isCouponDetailPage) {
                  if (window.history.length > 1) {
                    navigate(-1);
                  } else {
                    navigate('/');
                  }
                } else {
                  navigate(-1);
                }
              }}
            >
              <img src="/back_arrow.svg" alt="戻る" />
            </div>
          ) : (
            <div></div>
          )}
          <div className="text-18px text-secondary font-semibold font-hiragino leading-6.5 truncate">
            {title && title}
          </div>
          <div onClick={() => closeWindow(sdk)}>
            <img src="/close.svg" alt="" />
          </div>
        </div>
      </header>
      <div
        className={`${getTenantMessage?.message ? 'h-76px' : 'h-48px'}`}
      ></div>
    </>
  );
};

export default Header;
