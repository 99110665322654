import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '../templates/createOrUpdateUserLayout';
import { useState, useEffect } from 'react';
import { STORAGE_KEY } from '../../constants/storage';
import { ADDRESS_TYPE, AddressType } from '../../constants/location/address';
import { FC } from 'react';
import { extractCleanAddress } from '../../utils/address';

interface AddressBarProps {
  selectedAddressType: AddressType;
  currentAddress?: string;
  onLocationUpdate: () => void;
}

export const AddressBar: FC<AddressBarProps> = ({
  selectedAddressType,
  currentAddress,
  onLocationUpdate,
}) => {
  const { user } = useOutletContext<OutletContext>();

  const [isHidden, setIsHidden] = useState(() => {
    return localStorage.getItem(STORAGE_KEY.ADDRESS_HIDDEN) === 'true';
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY.ADDRESS_HIDDEN, isHidden.toString());
  }, [isHidden]);

  return (
    <div className="flex flex-col gap-8px box-border w-full max-w-[390px] mx-auto px-16px">
      <div className="flex items-center justify-between gap-12px box-border w-full min-h-50px py-8px px-16px rounded-8px bg-#FFF text-14px text-secondary font-hiragino leading-5 relative">
        <div
          className={`break-all w-full transition-opacity ${
            selectedAddressType === ADDRESS_TYPE.CURRENT ? "" : isHidden ? 'bg-gray-200 rounded-4px h-20px' : ''
          }`}
        >
          {selectedAddressType === ADDRESS_TYPE.CURRENT
            ? extractCleanAddress(currentAddress)
            : isHidden
              ? ''
              : user.address}
        </div>
        <div
          onClick={
            selectedAddressType === ADDRESS_TYPE.CURRENT
              ? () => onLocationUpdate()
              : () => setIsHidden(!isHidden)
          }
          className={`flex items-center justify-center inline-block h-14px p-8px text-12px font-semibold text-#FFF rounded-8px font-hiragino whitespace-nowrap ${selectedAddressType === ADDRESS_TYPE.CURRENT ? 'bg-#1BA368 w-50px' : `bg-#F39828 ${isHidden ? 'w-34px' : 'w-50px'}`}`}
        >
          {selectedAddressType === ADDRESS_TYPE.CURRENT ? (
            <UpdateAddressButton />
          ) : isHidden ? (
            '表示'
          ) : (
            '非表示'
          )}
        </div>
      </div>
    </div>
  );
};

const UpdateAddressButton = () => {
  return (
    <div className="flex items-center">
      <span className="material-symbols-rounded text-16px text-#FFFFFF">
        refresh
      </span>
      <div className="px-2px">更新</div>
    </div>
  );
};
export default AddressBar;
