import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import SearchSelectionBar from '../organisms/searchSelectionBar';
import Footer from '../organisms/footer';
import Header from '../organisms/Header';
import AddressBar from '../organisms/addressBar';
import Loading from '../organisms/loading';
import { PlainMessage } from '@bufbuild/protobuf';
import { GetDistributedCouponsByTenantIDSortedByDistanceResponse } from 'schema/gen/es/foodlosscoupon/app/v1/app_pb';
import { api } from '../../lib/api';
import { parseError } from '../../utils/errors';
import LightLoading from '../organisms/lightLoading';
import SearchConditionBar from '../organisms/searchConditionBar';
import { isSoldOutToday } from '../../utils/date';
import { useLocationSearch } from '../../hooks/useLocationSearch';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

const SearchCouponByDistancePage: FC = () => {
  const [couponList, setCouponList] = useState<
    | PlainMessage<GetDistributedCouponsByTenantIDSortedByDistanceResponse>
    | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const {
    selectedAddressType,
    selectedDistance,
    currentLocation,
    handleAddressTypeChange,
    handleDistanceChange,
    getSearchParams,
    locationError,
    setLocationError,
    updateLocation,
    setCurrentLocation,
  } = useLocationSearch();

  const LIMIT = 20;

  const fetchCoupons = useCallback(
    async (newPageNum: number) => {
      if (isLoading) return;
      setIsLoading(true);

      try {
        const searchParams = await getSearchParams();
        if (!searchParams) return;
        const response =
          await api.getDistributedCouponsByTenantIDSortedByDistance({
            perPage: BigInt(LIMIT),
            pageNum: BigInt(newPageNum),
            ...searchParams,
          });

        if (newPageNum === 1) {
          setCouponList({ coupons: response.coupons || [] });
        } else if (response?.coupons.length) {
          setCouponList((prev) => ({
            ...prev,
            coupons: [...(prev?.coupons || []), ...response.coupons],
          }));
        }
        setPageNum(newPageNum);
        setHasMore(response?.coupons.length === LIMIT);
      } catch (error) {
        console.error('Failed to fetch coupons:', error);
        setHasMore(false);
        const { statusCode, detail } = parseError(error);
        alert(
          `クーポンの取得に失敗しました\nエラーメッセージ：${detail}\nエラーコード：${statusCode}`,
        );
      } finally {
        setIsLoading(false);
        setIsInitialLoading(false);
      }
    },
    [getSearchParams, isLoading, selectedAddressType, selectedDistance],
  );

  useEffect(() => {
    const updateSearch = async () => {
      setLocationError(null);
      setPageNum(1);
      setHasMore(true);
      await fetchCoupons(1);
    };
    updateSearch();
  }, [selectedAddressType, selectedDistance, currentLocation]);

  const { bottomRef } = useInfiniteScroll({
    isLoading,
    hasMore,
    pageNum,
    onLoadMore: fetchCoupons,
    dependencies: [selectedAddressType, selectedDistance],
  });

  if (isInitialLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="クーポン" isShowBackArrow={false}></Header>
        <SearchSelectionBar searchSelection={'distance'} />
        <div className="pb-111px">
          <div className="flex flex-col gap-16px">
            <SearchConditionBar
              selectedAddressType={selectedAddressType}
              selectedDistance={selectedDistance}
              onAddressTypeChange={handleAddressTypeChange}
              onDistanceChange={handleDistanceChange}
              setCurrentLocation={setCurrentLocation}
            />
            <AddressBar
              selectedAddressType={selectedAddressType}
              currentAddress={currentLocation?.address}
              onLocationUpdate={updateLocation}
            ></AddressBar>
            <div className="flex flex-wrap justify-start gap-16px w-full max-w-[390px] box-border mx-auto px-16px">
              {locationError && (
                <div className="py-48px w-full">
                  <div className="flex justify-center ">
                    <span className="material-symbols-rounded text-[80px] text-#4B556380">
                      confirmation_number
                    </span>
                  </div>
                  <div className="text-14px text-tertiary text-center leading-5">
                    {locationError}
                  </div>
                </div>
              )}
              {!locationError &&
                (!couponList?.coupons || couponList?.coupons.length === 0) && (
                  <div className="py-48px w-full">
                    <div className="flex justify-center ">
                      <span className="material-symbols-rounded text-[80px] text-#4B556380">
                        confirmation_number
                      </span>
                    </div>
                    <div className="text-14px text-tertiary text-center leading-5">
                      配布中のクーポンはありません
                    </div>
                  </div>
                )}
              {!locationError &&
                couponList?.coupons &&
                couponList?.coupons.length > 0 &&
                couponList?.coupons.map((coupon, index) => {
                  return (
                    <Link
                      key={coupon.coupon?.id}
                      to={`/coupons/${coupon.coupon?.id}`}
                      className="relative bg-#FFF rounded-12px no-underline shadow-DropShadowHigh"
                      ref={
                        index === couponList.coupons.length - 1
                          ? bottomRef
                          : null
                      }
                      style={{
                        width: 'calc((100% - 16px) / 2)',
                      }}
                    >
                      <div className="flex justify-end w-full h-26px p-4px absolute">
                        <div className="ml-auto mr-10px inline-block h-18px px-8px py-4px bg-#FFF rounded-20px border-solid border-1.5px border-#F39828 text-12px text-center font-bold text-#F39828 font-hiragino leading-150">
                          {coupon.distance >= 1000
                            ? `+${
                                (Number(coupon.distance) / 1000) % 1 === 0
                                  ? (Number(coupon.distance) / 1000).toFixed(0)
                                  : (Number(coupon.distance) / 1000).toFixed(1)
                              }km`
                            : `+${Math.floor(Number(coupon.distance) / 100) * 100}m`}
                        </div>
                      </div>
                      <div className="h-120px">
                        <img
                          src={`${location.origin}/storage/${coupon.coupon?.imagePath}`}
                          alt={coupon.coupon?.couponName}
                          className="w-full h-120px object-cover rounded-t-12px"
                          loading="lazy"
                        />
                      </div>
                      <div className="flex flex-col gap-8px w-151px px-8px py-8px">
                        <div className="w-151px h-40px text-14px font-semibold text-#111827 font-hiragino break-words line-clamp-2 leading-5">
                          {coupon.coupon?.couponName}
                        </div>
                        <div>
                          <span className="inline-block h-16px px-8px py-6px bg-#E9573E rounded-8px text-12px text-#FFF font-semibold leading-4 font-hiragino">
                            {coupon.coupon?.discountMethod ==
                            'percentage_discount'
                              ? coupon.coupon?.discountValue + '%OFF'
                              : coupon.coupon?.discountValue + '円引き'}
                          </span>
                        </div>
                      </div>
                      {coupon.coupon?.isSoldOut &&
                        isSoldOutToday(coupon.coupon?.soldOutAt) && (
                          <>
                            <div
                              className={`${'z-2 rotate--9.23deg absolute top-3/10 left-1/10 w-104px h-42px border-solid border-4px border-#FFF font-bold text-26px text-#FFF rounded-8px py-8px px-12px text-center leading-41px'}`}
                            >
                              売り切れ
                            </div>
                            <div
                              className={`${'z-1 absolute top-0 left-0 opacity-20 w-full h-full bg-black rounded-12px'}`}
                            ></div>
                          </>
                        )}
                    </Link>
                  );
                })}
            </div>
            {isLoading && (
              <div className="flex justify-center">
                <LightLoading />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SearchCouponByDistancePage;
