import classnames from 'classnames';

type Props = {
  size: number;
  inline?: boolean;
};

// 異なる意味のコンポーネント間の余白を開けるためのスペーサー
// 同レベルかつ同じ意味のコンポーネントや要素間の余白を開ける場合Stackを使用してください
export const Space = ({ size, inline }: Props) => {
  const sizeInRem = `${size / 4}rem`;

  return (
    <span
      className={classnames([inline ? 'inline-block' : 'block'])}
      style={{
        ...(inline
          ? {
              width: sizeInRem,
              minWidth: sizeInRem,
            }
          : {
              height: sizeInRem,
              minHeight: sizeInRem,
            }),
      }}
    ></span>
  );
};
