export const DISTANCE = {
  ALL: 'all',
  ONE_KM: '1km',
  THREE_KM: '3km',
  FIVE_KM: '5km',
  TEN_KM: '10km',
} as const;

export type Distance = (typeof DISTANCE)[keyof typeof DISTANCE];

export const DISTANCE_LABELS: Record<Distance, string> = {
  [DISTANCE.ALL]: 'すべて',
  [DISTANCE.ONE_KM]: '1km',
  [DISTANCE.THREE_KM]: '3km',
  [DISTANCE.FIVE_KM]: '5km',
  [DISTANCE.TEN_KM]: '10km',
};
