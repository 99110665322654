import {
  getSubscriptionIdWithSignature,
  readResourceWithSignature,
  MergedSourceResources,
} from '@pocketsign/in-app-sdk';
import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { sdk } from '../../lib/sdk';
import { useMutationCreateOrUpdateUser } from '../../hooks/useQuery';
import { PlainMessage } from '@bufbuild/protobuf';
import { CreateOrUpdateUserResponse } from 'schema/gen/es/foodlosscoupon/app/v1/app_pb';
import Loading from '../organisms/loading';
import { tokenAtom } from '../../utils/atoms';
import { useSetAtom } from 'jotai';

export type OutletContext = {
  user: {
    subscriptionIdToken: string;
    address: string;
    gender: string;
    birthday: string;
    latitude: number;
    longitude: number;
  };
};

function CreateOrUpdateUserLayout() {
  const [user, setUser] = useState<{
    subscriptionIdToken: string;
    address: string;
    gender: string;
    birthday: string;
    latitude: number;
    longitude: number;
  } | null>(null);
  const createOrUpdateUser = useMutationCreateOrUpdateUser();
  const isRequestSent = useRef(false);
  const setToken = useSetAtom(tokenAtom);

  const getUserInfoFromApp = async () => {
    // 二重リクエスト防止
    if (isRequestSent.current) return; // すでにリクエストが実行されている場合、処理をスキップ
    isRequestSent.current = true; // リクエストを実行済みに設定

    let subscriptionIdToken: string = '';
    let municipalityAddressToken;
    let addressToken;
    let genderToken;
    let birthdayToken;
    const subscriptionIDRes = await getSubscriptionIdWithSignature(sdk);
    if (
      'subscriptionId' in subscriptionIDRes &&
      subscriptionIDRes.result === 'success'
    ) {
      // 'value' プロパティが存在し、'success' 結果である場合にのみ値を設定
      subscriptionIdToken = subscriptionIDRes.subscriptionId;
    } else {
      console.error('Error occurred:', subscriptionIDRes);
    }

    const municipalityAddressRes = await readResourceWithSignature(sdk, {
      resourceId: MergedSourceResources.cityAddress,
    });
    if (
      'value' in municipalityAddressRes &&
      municipalityAddressRes.result === 'success'
    ) {
      // 'value' プロパティが存在し、'success' 結果である場合にのみ値を設定
      municipalityAddressToken = municipalityAddressRes.value;
    } else {
      console.error('Error occurred:', municipalityAddressRes);
    }

    const addressRes = await readResourceWithSignature(sdk, {
      resourceId: MergedSourceResources.fullAddress,
    });
    if ('value' in addressRes && addressRes.result === 'success') {
      // 'value' プロパティが存在し、'success' 結果である場合にのみ値を設定
      addressToken = addressRes.value;
    } else {
      console.error('Error occurred:', addressRes);
    }

    const genderRes = await readResourceWithSignature(sdk, {
      resourceId: MergedSourceResources.gender,
    });
    if ('value' in genderRes && genderRes.result === 'success') {
      // 'value' プロパティが存在し、'success' 結果である場合にのみ値を設定
      genderToken = genderRes.value;
    } else {
      console.error('Error occurred:', genderRes);
    }

    const birthdayRes = await readResourceWithSignature(sdk, {
      resourceId: MergedSourceResources.birthDate,
    });
    if ('value' in birthdayRes && birthdayRes.result === 'success') {
      // 'value' プロパティが存在し、'success' 結果である場合にのみ値を設定
      birthdayToken = birthdayRes.value;
    } else {
      console.error('Error occurred:', birthdayRes);
    }

    await createOrUpdateUser
      .mutateAsync({
        subscriptionIdToken: subscriptionIdToken,
        municipalityAddressToken: municipalityAddressToken,
        addressToken: addressToken,
        genderToken: genderToken,
        birthdayToken: birthdayToken,
      })
      .then((response: PlainMessage<CreateOrUpdateUserResponse>) => {
        setToken(response.token);
        setUser({
          subscriptionIdToken: (subscriptionIdToken as string) || '',
          address: response.user?.address || '',
          gender: response.user?.gender || '',
          birthday: response.user?.birthday || '',
          latitude: response.user?.latitude || 0,
          longitude: response.user?.longitude || 0,
        });
      });
  };

  useEffect(() => {
    getUserInfoFromApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user == null) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }
  return (
    <Outlet
      context={
        { user } satisfies {
          user: {
            subscriptionIdToken: string;
            address: string;
            gender: string;
            birthday: string;
            latitude: number;
            longitude: number;
          };
        }
      }
    />
  );
}

export default CreateOrUpdateUserLayout;
