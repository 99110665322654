import classnames from 'classnames';
import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  useQueryGetDistributedCouponsByShopID,
  useQueryGetShopByID,
} from '../../hooks/useQuery';
import { parseError } from '../../utils/errors';
import { getGoogleMapsLink } from '../../utils/googlemap';
import { Space } from '../atoms/Space.tsx';
import { Bleed } from '../molecules/Bleed.tsx';
import { Reel } from '../molecules/Reel.tsx';
import GoogleMapsPicker from '../organisms/google-maps-picker';
import Header from '../organisms/Header.tsx';
import Loading from '../organisms/loading';

interface Coupon {
  id: string;
  couponName: string;
  imagePath: string;
  discountMethod: string;
  discountValue: bigint;
}

const ShopDetailPage: FC = () => {
  const { shopID } = useParams<{ shopID: string }>();
  const {
    data: shop,
    isLoading: isShopLoading,
    error: shopError,
  } = useQueryGetShopByID(shopID || '');
  // FIXME : エラー表示
  const {
    data: couponData,
    isLoading: isCouponLoading,
    isError: isCouponError,
  } = useQueryGetDistributedCouponsByShopID(shopID);

  useEffect(() => {
    if (shopError) {
      console.error('ショップ情報の取得に失敗:', shopError);

      const { statusCode, detail } = parseError(shopError);

      alert(
        `ショップ詳細の取得に失敗しました\nエラーメッセージ：${detail}\nエラーコード：${statusCode}`,
      );
    }
  }, [shopError]);

  if (isShopLoading || isCouponLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  const hasCoupons = couponData?.coupons && couponData.coupons.length > 0;

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="ショップ詳細" isShowBackArrow={true}></Header>
        <main className="min-h-screen p-16px">
          {!isCouponError && hasCoupons && (
            <>
              <aside>
                <CouponList coupons={couponData.coupons} />
              </aside>
              <Space size={4} />
            </>
          )}
          <article className="flex flex-col gap-12px p-16px bg-#FFF rounded-12px">
            <h2 className="text-16px text-#111827 font-semibold font-hiragino leading-5 break-all m-0">
              {shop?.shop?.name}
            </h2>
            {shop?.shop?.description && (
              <p className="text-14px text-#111827 font-hiragino leading-5 break-all whitespace-pre-line m-0">
                {shop?.shop?.description}
              </p>
            )}
            <div className="w-full aspect-[4/3] mx-auto rounded-8px overflow-hidden">
              <img
                src={`${location.origin}/storage/${shop?.shop?.imagePath}`}
                alt={shop?.shop?.name}
                className="w-full h-full object-cover rounded-8px"
              />
            </div>
            {shop?.shop?.openingHours && (
              <div className="flex flex-col gap-8px">
                <h3 className="text-14px text-#111827 font-semibold font-hiragino leading-5 m-0">
                  営業時間
                </h3>
                <div className="text-14px text-#111827 font-hiragino leading-5 break-all whitespace-pre-line">
                  {shop?.shop?.openingHours}
                </div>
              </div>
            )}
            <div className="flex flex-col gap-4px">
              <h3 className="text-14px text-#111827 font-semibold font-hiragino leading-5 m-0">
                住所
              </h3>
              <a
                href={getGoogleMapsLink(shop?.shop?.address || '')}
                target="_blank"
                rel="noreferrer"
                className="text-14px text-#001EFB font-hiragino leading-5 break-all decoration-none"
              >
                {shop?.shop?.address}
              </a>
              <div className="h-262px">
                <GoogleMapsPicker
                  latitute={shop?.shop?.latitude || 35.68123620000001}
                  longitude={shop?.shop?.longitude || 139.7671248}
                  onChangeLocation={() => {}}
                />
              </div>
            </div>
            {shop?.shop?.phoneNumber && (
              <div className="flex flex-col gap-8px">
                <h3 className="text-14px text-#111827 font-semibold font-hiragino leading-5 m-0">
                  お問合せ
                </h3>
                <div>
                  <a
                    href={`tel:${shop.shop.phoneNumber}`}
                    className="inline-flex gap-2px h-20px pl-8px pr-12px py-6px bg-transparent rounded-12px border-solid border-1px no-underline border-disableText"
                  >
                    <div className="">
                      <span className="material-symbols-rounded text-18px text-#374151CC leading-5.5">
                        call
                      </span>
                    </div>
                    <div className="text-12px text-secondary font-semibold font-hiragino leading-5">
                      {shop?.shop?.phoneNumber}
                    </div>
                  </a>
                </div>
              </div>
            )}
            {shop?.shop?.homepageUrl && (
              <div className="flex flex-col gap-8px">
                <h3 className="text-14px text-#111827 font-semibold font-hiragino leading-5 m-0">
                  ホームページ
                </h3>
                <a
                  href={shop?.shop?.homepageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-14px text-#001EFB font-hiragino leading-5 no-underline cursor-pointer break-all"
                >
                  {shop?.shop?.homepageUrl}
                </a>
              </div>
            )}
          </article>
        </main>
      </div>
    </>
  );
};

const CouponList = ({ coupons }: { coupons: Coupon[] }) => {
  return (
    <>
      <h2 className="m-0 font-hiragino text-ps-text-secondary text-body font-semibold">
        配布中クーポン
      </h2>
      <Space size={3} />
      <Bleed x={4}>
        <Reel gap={3} padding={4} className="-my-4">
          {coupons.map((coupon: Coupon) => (
            <Link
              key={coupon.id}
              to={`/coupons/${coupon.id}`}
              className={classnames([
                'w-[10rem] relative bg-#FFF rounded-3 shadow-DropShadowHigh overflow-hidden decoration-none transition',
                'after:content-[""] after:absolute after:top-0 after:size-full after:bg-ps-action-hoverOverlay after:opacity-0 after:pointer-events-none after:transition-opacity',
                '@hover-shadow-DropShadowLow @hover-after:opacity-100',
                'active:shadow-DropShadowLow active:after:opacity-100',
              ])}
            >
              <div className="relative w-full aspect-4/3">
                <img
                  src={`${location.origin}/storage/${coupon.imagePath}`}
                  alt={coupon.couponName}
                  className="block size-full object-cover"
                  loading="lazy"
                />
              </div>
              <div className="px-2.5 py-2">
                <div className="min-h-10 text-14px font-semibold text-#111827 font-hiragino break-words line-clamp-2 leading-5">
                  {coupon.couponName}
                </div>
                <div>
                  <span className="inline-block py-1 px-2 bg-#E9573E rounded-2 border-solid border-1.5px border-white text-12px font-bold text-white font-hiragino leading-snug">
                    {coupon.discountMethod == 'percentage_discount'
                      ? coupon.discountValue + '%OFF'
                      : coupon.discountValue + '円引き'}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </Reel>
      </Bleed>
    </>
  );
};

export default ShopDetailPage;
