import { atomWithStorage } from "jotai/utils";
import { STORAGE_KEY } from "../constants/storage";
// sessionStorage を使用するカスタムストレージ
const sessionStorageWrapper = {
  getItem: (key: string): string | null => sessionStorage.getItem(key),
  setItem: (key: string, newValue: string | null) => {
    if (newValue === null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, newValue);
    }
  },
  removeItem: (key: string) => sessionStorage.removeItem(key),
};

// token を sessionStorage に保存する atom
export const tokenAtom = atomWithStorage<null | string>(STORAGE_KEY.TOKEN, null, sessionStorageWrapper);
