export const ADDRESS_TYPE = {
  REGISTERED: 'registered',
  CURRENT: 'current',
} as const;

export type AddressType = (typeof ADDRESS_TYPE)[keyof typeof ADDRESS_TYPE];

export const ADDRESS_TYPE_LABELS: Record<AddressType, string> = {
  [ADDRESS_TYPE.REGISTERED]: '登録住所から',
  [ADDRESS_TYPE.CURRENT]: '現在地から',
};
