import { useState, useRef, useEffect } from 'react';
import {
  ADDRESS_TYPE,
  DISTANCE,
  type AddressType,
  type Distance,
  ADDRESS_TYPE_LABELS,
  DISTANCE_LABELS,
} from '../../constants';
import { LocationWithAddress } from '../../hooks/useLocationSearch';

interface SearchConditionBarProps {
  selectedAddressType: AddressType;
  selectedDistance: Distance;
  onAddressTypeChange: (type: AddressType) => void;
  onDistanceChange: (distance: Distance) => void;
  setCurrentLocation: React.Dispatch<
    React.SetStateAction<LocationWithAddress | null>
  >;
}

const SearchConditionBar: React.FC<SearchConditionBarProps> = ({
  selectedAddressType,
  selectedDistance,
  onAddressTypeChange,
  onDistanceChange,
  setCurrentLocation,
}) => {
  const [isAddressTypeOpen, setIsAddressTypeOpen] = useState(false);
  const [isDistanceOpen, setIsDistanceOpen] = useState(false);
  const addressTypeMenuRef = useRef<HTMLDivElement>(null);
  const distanceMenuRef = useRef<HTMLDivElement>(null);

  const toggleAddressTypeMenu = () => setIsAddressTypeOpen((prev) => !prev);
  const toggleDistanceMenu = () => setIsDistanceOpen((prev) => !prev);

  const closeMenu = (event: MouseEvent) => {
    if (
      addressTypeMenuRef.current &&
      !addressTypeMenuRef.current.contains(event.target as Node)
    ) {
      setIsAddressTypeOpen(false);
    }
    if (
      distanceMenuRef.current &&
      !distanceMenuRef.current.contains(event.target as Node)
    ) {
      setIsDistanceOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  const addressTypeOptions = Object.entries(ADDRESS_TYPE)
    .filter(([, value]) => value !== selectedAddressType)
    .map(([, value]) => ({
      value,
      label: ADDRESS_TYPE_LABELS[value],
    }));

  const distanceOptions = Object.entries(DISTANCE)
    .filter(([, value]) => value !== selectedDistance)
    .map(([, value]) => ({
      value,
      label: DISTANCE_LABELS[value],
    }));

  return (
    <div className="flex justify-between gap-16px px-16px">
      <div className="relative text-left" ref={addressTypeMenuRef}>
        <div
          className={`flex items-center max-w-200px h-16px justify-start gap-4px border-solid rounded-12px bg-#FFF px-14px py-16px border-2px ${
            isAddressTypeOpen ? 'border-#F39828' : 'border-transparent'
          }`}
          id="menu-button"
          aria-expanded={isAddressTypeOpen}
          aria-haspopup="true"
          onClick={toggleAddressTypeMenu}
        >
          <div className="w-107px text-16px text-#111827">
            {ADDRESS_TYPE_LABELS[selectedAddressType]}
          </div>
          <span
            className={`material-symbols-rounded text-[24px] text-#9CA3AF80 ${isAddressTypeOpen ? 'rotate-180' : ''}`}
          >
            expand_more
          </span>
        </div>
        {isAddressTypeOpen && (
          <div
            className="absolute left-0 z-10 mt-3px box-border w-full border-1px border-lightGray border-solid p-4px rounded-12px bg-#FFF"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            {addressTypeOptions.map((option) => (
              <div
                key={option.value}
                className="h-24px px-14px py-12px rounded-10px leading-6 cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  setCurrentLocation(null);
                  onAddressTypeChange(option.value);
                  setIsAddressTypeOpen(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="relative text-left" ref={distanceMenuRef}>
        <div
          className={`flex items-center max-w-200px h-16px justify-start gap-4px border-solid rounded-12px bg-#FFF px-14px py-16px border-2px ${
            isDistanceOpen ? 'border-#F39828' : 'border-transparent'
          }`}
          id="menu-button"
          aria-expanded={isDistanceOpen}
          aria-haspopup="true"
          onClick={toggleDistanceMenu}
        >
          <div className="w-107px text-16px text-#111827">
            {DISTANCE_LABELS[selectedDistance]}
          </div>
          <span
            className={`material-symbols-rounded text-[24px] text-#9CA3AF80 ${isDistanceOpen ? 'rotate-180' : ''}`}
          >
            expand_more
          </span>
        </div>
        {isDistanceOpen && (
          <div
            className="absolute box-border left-0 z-10 mt-3px w-full border-1px border-lightGray border-solid p-4px rounded-12px bg-#FFF"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            {distanceOptions.map((option) => (
              <div
                key={option.value}
                className="h-24px px-14px py-12px rounded-10px cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  onDistanceChange(option.value);
                  setIsDistanceOpen(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchConditionBar;
