import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  requestPermission,
  MergedSourceResources,
  PushNotificationResourceId,
  GeolocationResources,
  closeWindow,
} from '@pocketsign/in-app-sdk';
import { sdk } from '../../lib/sdk';
import Loading from '../organisms/loading';

function RequestPermissionLayout() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const requestPermissions = async () => {
    const expireAt = new Date();
    expireAt.setFullYear(expireAt.getFullYear() + 1);
    const response = await requestPermission(sdk, [
      {
        resourceId: MergedSourceResources.cityAddress, // 市区町村までの住所
        verbs: [
          {
            verb: 'read' as const,
            term: 'always',
          },
        ],
      },
      {
        resourceId: MergedSourceResources.fullAddress, // 住所
        verbs: [
          {
            verb: 'read' as const,
            term: 'always',
          },
        ],
      },
      {
        resourceId: MergedSourceResources.gender, // 性別
        verbs: [
          {
            verb: 'read' as const,
            term: 'always',
          },
        ],
      },
      {
        resourceId: MergedSourceResources.birthDate, // 生年月日
        verbs: [
          {
            verb: 'read' as const,
            term: 'always',
          },
        ],
      },
      {
        resourceId: PushNotificationResourceId, // プッシュ通知を送るための権限
        verbs: [
          {
            verb: 'invoke' as const,
            term: 'always',
          },
        ],
      },
      {
        resourceId: GeolocationResources.normalAccuracy, // 端末の位置情報にアクセスするための権限
        verbs: [
          {
            verb: 'invoke',
            expireAt,
            term: 'temporary',
          },
        ],
      },
    ]);

    if (response.result !== 'agree') {
      console.error(`failed to request permission: ${response.result}`);
      closeWindow(sdk);
      return;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return <Outlet />;
}

export default RequestPermissionLayout;
