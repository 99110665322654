import { ReactNode } from 'react';

type BleedProps = {
  children: ReactNode;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  all?: number;
  x?: number; // 水平方向（左右）
  y?: number; // 垂直方向（上下）
  className?: string;
};

const toRemValue = (units: number) => `${units * 0.25}rem`;

export const Bleed = ({
  children,
  top,
  right,
  bottom,
  left,
  all,
  x,
  y,
  className,
}: BleedProps) => {
  const margins = {
    marginTop:
      all !== undefined
        ? `-${toRemValue(all)}`
        : y !== undefined
          ? `-${toRemValue(y)}`
          : top !== undefined
            ? `-${toRemValue(top)}`
            : undefined,
    marginRight:
      all !== undefined
        ? `-${toRemValue(all)}`
        : x !== undefined
          ? `-${toRemValue(x)}`
          : right !== undefined
            ? `-${toRemValue(right)}`
            : undefined,
    marginBottom:
      all !== undefined
        ? `-${toRemValue(all)}`
        : y !== undefined
          ? `-${toRemValue(y)}`
          : bottom !== undefined
            ? `-${toRemValue(bottom)}`
            : undefined,
    marginLeft:
      all !== undefined
        ? `-${toRemValue(all)}`
        : x !== undefined
          ? `-${toRemValue(x)}`
          : left !== undefined
            ? `-${toRemValue(left)}`
            : undefined,
  };

  return (
    <div className={className} style={margins}>
      {children}
    </div>
  );
};
