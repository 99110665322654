import { PlainMessage, Timestamp } from "@bufbuild/protobuf";
import dayjs from "dayjs";

const week = ["日", "月", "火", "水", "木", "金", "土"];
export const formatDate = (date: Date, format: string) => {
  format = format.replace(/yyyy/g, String(date.getFullYear()));
  format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2)); // 2桁の月
  format = format.replace(/M/g, String(date.getMonth() + 1)); // 1桁の月
  format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2)); // 2桁の日
  format = format.replace(/d/g, String(date.getDate())); // 1桁の日
  format = format.replace(/ww/g, week[date.getDay()]);
  format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
  format = format.replace(/H/g, String(date.getHours()));
  format = format.replace(/m/g, String(date.getMinutes()));
  format = format.replace(/s/g, String(date.getSeconds()));
  return format;
};

export const dayjsToTimestamp = (
  date: dayjs.Dayjs | null
): Timestamp | undefined => {
  if (!date) return undefined; // dateがnullまたはundefinedの場合はundefinedを返す

  return new Timestamp({
    seconds: BigInt(date.unix()), // 秒数
    nanos: date.millisecond() * 1e6, // ミリ秒をナノ秒に変換
  });
};

export const formatTimestamp = (timestamp: Timestamp | undefined): string => {
  if (!timestamp || timestamp.seconds === BigInt(-62135596800)) {
    return ""; // undefinedまたはデフォルトの0001-01-01 00:00:00の場合は空文字列を返す
  }

  const date = new Date(Number(timestamp.seconds) * 1000); // bigintをnumberに変換してからミリ秒に変換
  return date.toLocaleString(); // ローカルな日時形式の文字列に変換
};

export const isSoldOutToday = (soldOutAt?: PlainMessage<Timestamp>) => {
  if (!soldOutAt?.seconds) return false;

  return dayjs.unix(Number(soldOutAt.seconds)).isSame(dayjs(), "day");
};
