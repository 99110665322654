import { PlainMessage } from '@bufbuild/protobuf';
import classnames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { GetShopsByTenantIDSortedByDistanceResponse } from 'schema/gen/es/foodlosscoupon/app/v1/app_pb';
import { api } from '../../lib/api';
import { parseError } from '../../utils/errors';
import AddressBar from '../organisms/addressBar';
import Footer from '../organisms/footer';
import Header from '../organisms/Header.tsx';
import LightLoading from '../organisms/lightLoading';
import Loading from '../organisms/loading';
import SearchConditionBar from '../organisms/searchConditionBar';
import { useLocationSearch } from '../../hooks/useLocationSearch';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

const ShopListPage: FC = () => {
  const [shopList, setShopList] = useState<
    PlainMessage<GetShopsByTenantIDSortedByDistanceResponse> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const {
    selectedAddressType,
    selectedDistance,
    currentLocation,
    handleAddressTypeChange,
    handleDistanceChange,
    getSearchParams,
    locationError,
    setLocationError,
    updateLocation,
    setCurrentLocation,
  } = useLocationSearch();

  const LIMIT = 20;

  const fetchShops = useCallback(
    async (newPageNum: number) => {
      if (isLoading) return;
      setIsLoading(true);

      try {
        const searchParams = await getSearchParams();
        const response = await api.getShopsByTenantIDSortedByDistance({
          perPage: BigInt(LIMIT),
          pageNum: BigInt(newPageNum),
          ...searchParams,
        });

        if (newPageNum === 1) {
          setShopList({ shops: response.shops || [] });
        } else if (response?.shops.length) {
          setShopList((prev) => ({
            ...prev,
            shops: [...(prev?.shops || []), ...response.shops],
          }));
        }
        setPageNum(newPageNum);
        setHasMore(response?.shops.length === LIMIT);
      } catch (error) {
        console.error('Failed to fetch shops:', error);
        setHasMore(false);
        const { statusCode, detail } = parseError(error);
        alert(
          `ショップの取得に失敗しました\nエラーメッセージ：${detail}\nエラーコード：${statusCode}`,
        );
      } finally {
        setIsLoading(false);
        if (isInitialLoading) setIsInitialLoading(false);
      }
    },
    [getSearchParams, isLoading, selectedAddressType, selectedDistance],
  );

  // 検索条件が変更されたら再取得
  useEffect(() => {
    const updateSearch = async () => {
      setLocationError(null);
      setPageNum(1);
      setHasMore(true);
      await fetchShops(1);
    };
    updateSearch();
  }, [selectedAddressType, selectedDistance, currentLocation]);

  const { bottomRef } = useInfiniteScroll({
    isLoading,
    hasMore,
    pageNum,
    onLoadMore: fetchShops,
    dependencies: [selectedAddressType, selectedDistance],
  });

  if (isInitialLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="ショップ" isShowBackArrow={false}></Header>
        <div className="pb-111px">
          <div className="flex flex-col gap-16px">
            <SearchConditionBar
              selectedAddressType={selectedAddressType}
              selectedDistance={selectedDistance}
              onAddressTypeChange={handleAddressTypeChange}
              onDistanceChange={handleDistanceChange}
              setCurrentLocation={setCurrentLocation}
            />
            <AddressBar
              selectedAddressType={selectedAddressType}
              currentAddress={currentLocation?.address}
              onLocationUpdate={updateLocation}
            ></AddressBar>
            <div className="w-full max-w-[390px] box-border mx-auto px-16px">
              {locationError && (
                <div className="py-48px w-full">
                  <div className="flex justify-center ">
                    <span className="material-symbols-rounded text-[80px] text-#4B556380">
                      storefront
                    </span>
                  </div>
                  <div className="text-14px text-tertiary text-center leading-5">
                    {locationError}
                  </div>
                </div>
              )}
              {!locationError &&
                (!shopList?.shops || shopList.shops.length === 0) && (
                  <div className="py-48px w-full">
                    <div className="flex justify-center ">
                      <span className="material-symbols-rounded text-[80px] text-#9CA3AF80">
                        storefront
                      </span>
                    </div>
                    <div className="text-14px text-tertiary text-center leading-5">
                      ショップが登録されていません
                    </div>
                  </div>
                )}
              {!locationError &&
                shopList?.shops &&
                shopList.shops.length > 0 && (
                  <div className="flex flex-wrap gap-4">
                    {shopList.shops.map((shop, index) => {
                      return (
                        <>
                          <Link
                            key={shop.shop?.id}
                            to={`/shops/${shop.shop?.id}`}
                            ref={
                              index === shopList.shops.length - 1
                                ? bottomRef
                                : null
                            }
                            className={classnames([
                              'relative w-[calc(50%-0.5rem)] bg-#FFF rounded-3 shadow-DropShadowHigh overflow-hidden decoration-none transition',
                              'after:content-[""] after:absolute after:top-0 after:size-full after:bg-ps-action-hoverOverlay after:opacity-0 after:pointer-events-none after:transition-opacity',
                              '@hover-shadow-DropShadowLow @hover-after:opacity-100',
                              'active:shadow-DropShadowLow active:after:opacity-100',
                            ])}
                          >
                            <div className="relative w-full aspect-4/3">
                              <img
                                src={`${location.origin}/storage/${shop?.shop?.imagePath}`}
                                alt={shop.shop?.name}
                                className="block size-full object-cover"
                                loading="lazy"
                              />
                              <div className="right-1 top-1 absolute">
                                <div className="inline-block px-2 py-1 bg-#FFF rounded-full border-solid border-1.5px border-#F39828 text-12px text-center font-bold text-#F39828 font-hiragino leading-snug">
                                  {shop.distance >= 1000
                                    ? `+${
                                        (Number(shop.distance) / 1000) % 1 === 0
                                          ? (
                                              Number(shop.distance) / 1000
                                            ).toFixed(0) // 小数点第一位が0なら整数表示
                                          : (
                                              Number(shop.distance) / 1000
                                            ).toFixed(1)
                                      }km` // それ以外は小数点第一位まで表示
                                    : `+${Math.floor(Number(shop.distance) / 100) * 100}m`}
                                </div>
                              </div>
                              {shop.distributedCoupon && (
                                <div className="right-1 bottom-1 absolute">
                                  <div className="inline-block py-1 px-2 bg-#E9573E rounded-2 border-solid border-1.5px border-white text-12px font-bold text-white font-hiragino leading-snug">
                                    クーポン配布中
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="min-h-10 px-2.5 py-2">
                              <div className="text-14px font-semibold text-#111827 font-hiragino break-words line-clamp-2 leading-5">
                                {shop.shop?.name}
                              </div>
                            </div>
                          </Link>
                        </>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
          {isLoading && (
            <div className="flex justify-center">
              <LightLoading />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ShopListPage;
