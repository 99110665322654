import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { openQrScanner } from "@pocketsign/in-app-sdk";
import { sdk } from "../../lib/sdk";
import { useQueryGetCouponByID } from "../../hooks/useQuery";
import { formatDate, formatTimestamp, isSoldOutToday } from "../../utils/date";
import { Timestamp } from "@bufbuild/protobuf";
import dayjs from "dayjs";
import Header from "../organisms/Header";
import Loading from "../organisms/loading";
import { parseError } from "../../utils/errors";
import { STORAGE_KEY } from "../../constants/storage";

const CouponDetailPage: FC = () => {
  const { couponID } = useParams<{ couponID: string }>();
  const {
    data: getCouponByID,
    isLoading,
    error,
  } = useQueryGetCouponByID(couponID || "");
  const navigate = useNavigate();
  const [isNotDistributed, setIsNotDistributed] = useState(false);

  const calculateDiscountedPrice = (): string | null => {
    const originalPriceNumber = parseFloat(
      String(getCouponByID?.coupon?.originalPrice)
    );
    const discountValueNumber = parseFloat(
      String(getCouponByID?.coupon?.discountValue)
    );

    if (isNaN(originalPriceNumber) || isNaN(discountValueNumber)) {
      return null;
    }

    if (getCouponByID?.coupon?.discountMethod === "percentage_discount") {
      const discountedPrice =
        originalPriceNumber * (1 - discountValueNumber / 100);
      return discountedPrice.toFixed(0); // 小数点以下を切り捨てて整数値に
    } else if (
      getCouponByID?.coupon?.discountMethod === "fixed_amount_discount"
    ) {
      const discountedPrice = originalPriceNumber - discountValueNumber;
      return discountedPrice.toFixed(0); // 小数点以下を切り捨てて整数値に
    }

    return null;
  };

  const distributionEndAt = (
    getCouponByID?.coupon?.distributionEndAt as Timestamp
  )?.toDate();
  const isToday = distributionEndAt && dayjs().isSame(distributionEndAt, "day");
  const formattedDate = formatDate(
    distributionEndAt ?? new Date(0),
    "M/d(ww)HH:mm"
  );

  useEffect(() => {
    if (error) {
      console.error("クーポン情報の取得に失敗:", error);

      const { statusCode, detail } = parseError(error);

      alert(
        `クーポン詳細の取得に失敗しました\nエラーメッセージ：${detail}\nエラーコード：${statusCode}`
      );
    }
  }, [error]);

  useEffect(() => {
    setIsNotDistributed(false);
    if (!getCouponByID?.coupon?.isDistributed) {
      setIsNotDistributed(true);
    }
    if (
      formatTimestamp(getCouponByID?.coupon?.distributionEndAt as Timestamp)
    ) {
      const distributionEndDate = (
        getCouponByID?.coupon?.distributionEndAt as Timestamp
      ).toDate();
      const currentDate = new Date();
      if (currentDate > distributionEndDate) {
        setIsNotDistributed(true);
      }
    }
    if (
      formatTimestamp(getCouponByID?.coupon?.distributionStartAt as Timestamp)
    ) {
      const distributionStartAt = (
        getCouponByID?.coupon?.distributionStartAt as Timestamp
      ).toDate();
      const currentDate = new Date();
      if (currentDate < distributionStartAt) {
        setIsNotDistributed(true);
      }
    }
  }, [getCouponByID]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header
          title="クーポン詳細"
          isShowBackArrow={true}
          isCouponDetailPage={true}
        ></Header>
        <div>
          <div className="flex flex-col gap-10px p-16px">
            {isNotDistributed && (
              <div className="h-20px px-12px py-10px bg-#E5E7EB rounded-4px leading-5 text-14px font-semibold text-secondary">
                {getCouponByID?.coupon?.isSoldOut &&
                isSoldOutToday(getCouponByID?.coupon?.soldOutAt)
                  ? "売り切れのため、現在配布していません"
                  : "現在配布していません"}
              </div>
            )}
            <div className="flex flex-col gap-16px mb-141px p-16px bg-#FFF rounded-12px">
              <div className="flex flex-col">
                <div className="flex gap-8px">
                  <div className="text-16px text-#111827 font-hiragino font-semibold leading-9 break-all">
                    {getCouponByID?.shop?.name}
                  </div>
                  <div
                    onClick={() =>
                      navigate(`/shops/${getCouponByID?.shop?.id}`)
                    }
                    className="flex gap-2px ml-auto min-w-68px h-16px p-8px border-solid border-1px border-lightGray bg-#F8FAFC rounded-8px"
                  >
                    <div className="">
                      <span className="material-symbols-rounded text-16px">
                        storefront
                      </span>
                    </div>
                    <div className="text-12px font-semibold text-#111827 font-hiragino leading-16.2px">
                      ショップ
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4px">
                <div className="text-20px text-#303846 font-hiragino leading-32px break-all">
                  {getCouponByID?.coupon?.couponName}
                </div>
                <div>
                  <div className="flex gap-8px">
                    <div className="text-28px text-#B91C1C font-semibold font-hiragino leading-44.8px break-all">
                      -
                      {getCouponByID?.coupon?.discountMethod ==
                      "percentage_discount"
                        ? getCouponByID?.coupon?.discountValue + "%"
                        : getCouponByID?.coupon?.discountValue + "円"}
                    </div>
                    {getCouponByID?.coupon?.isSetOriginalPrice && (
                      <div className="flex gap-8px">
                        <div className="flex">
                          <div className="text-28px text-#111827 font-semibold font-hiragino leading-44.8px break-all">
                            {calculateDiscountedPrice()}
                          </div>
                          <div className="text-20px text-#111827 font-semibold font-hiragino leading-48px">
                            円
                          </div>
                        </div>
                        <div className="text-16px text-#111827 font-hiragino leading-50px">
                          税込
                        </div>
                      </div>
                    )}
                  </div>
                  {getCouponByID?.coupon?.isSetOriginalPrice && (
                    <div className="text-16px text-#111827 line-through font-hiragino leading-24px break-all">
                      (定価{String(getCouponByID?.coupon?.originalPrice)}円)
                    </div>
                  )}
                </div>
              </div>
              {formatTimestamp(
                getCouponByID?.coupon?.distributionEndAt as Timestamp
              ) != "" && (
                <div className="text-16px font-semibold text-#047857 font-hiragino leading-24px break-all">
                  {isToday ? "本日" : ""}
                  {formattedDate} まで
                </div>
              )}
              {getCouponByID?.coupon?.remarks && (
                <div
                  className="px-12px py-8px bg-#E8FCF2 rounded-4px text-14px text-#111827 font-hiragino leading-19.6px break-all"
                  dangerouslySetInnerHTML={{
                    __html: getCouponByID.coupon.remarks.replace(
                      /\n/g,
                      "<br />"
                    ),
                  }}
                />
              )}
              <div className="flex flex-col gap-8px">
                <div className="w-full aspect-[4/3] mx-auto rounded-8px overflow-hidden">
                  <img
                    src={`${location.origin}/storage/${getCouponByID?.coupon?.imagePath}`}
                    alt={getCouponByID?.coupon?.couponName}
                    className="w-full h-full object-cover rounded-8px"
                  />
                </div>
                {getCouponByID?.coupon?.description && (
                  <div
                    className="text-14px text-secondary font-hiragino leading-19.6px break-all"
                    dangerouslySetInnerHTML={{
                      __html: getCouponByID?.coupon?.description.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}
              </div>
              {getCouponByID?.coupon?.reason && (
                <div className="flex flex-col gap-4px w-326px">
                  <div className="text-14px font-semibold text-#111827 font-hiragino">
                    クーポン配布理由
                  </div>
                  <div
                    className="text-14px text-#111827 font-hiragino leading-19.6px break-all"
                    dangerouslySetInnerHTML={{
                      __html: getCouponByID?.coupon?.reason.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                </div>
              )}
              <div className="text-14px text-#B91C1C font-hiragino leading-19.6px">
                ※クーポンは商品の売れ行き次第で品切れになる場合がございます
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotDistributed && (
        <div className="fixed bottom-0 box-border w-full h-141px px-16px pt-36px pb-48px border-t-solid border-t-1px border-t-lightGray bg-#F0FDF4 font-hiragino">
          <button
            onClick={() => {
              sessionStorage.setItem(STORAGE_KEY.COUPON_ID, couponID || "");
              openQrScanner(sdk);
            }}
            className="block w-full h-56px bg-#1BA368 rounded-16px border-none shadow-ButtonShadow text-16px font-semibold text-#FFF font-hiragino"
          >
            お店のQRコードを読み取る
          </button>
        </div>
      )}
    </>
  );
};

export default CouponDetailPage;
