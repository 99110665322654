import { FC, useEffect, useState } from "react";
import Footer from "../organisms/footer";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../templates/createOrUpdateUserLayout";
import { sdk } from "../../lib/sdk";
import { openServiceSettings } from "@pocketsign/in-app-sdk";
import Header from "../organisms/Header";
import { STORAGE_KEY } from "../../constants/storage";

const SettingsPage: FC = () => {
  const { user } = useOutletContext<OutletContext>();
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [isHidden, setIsHidden] = useState(() => {
    return localStorage.getItem(STORAGE_KEY.PERSONAL_INFO_HIDDEN) === "true";
  });

  useEffect(() => {
    setAddress(user.address);

    if (user.gender === "male") {
      setGender("男");
    } else if (user.gender === "female") {
      setGender("女");
    } else {
      setGender("その他");
    }

    setBirthday(formatDate(user.birthday));
  }, []);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY.PERSONAL_INFO_HIDDEN, isHidden.toString());
  }, [isHidden]);

  const formatDate = (dateString: string): string => {
    const datePart = dateString.split(" ")[0];
    const date = new Date(datePart);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    return date.toLocaleDateString("ja-JP", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="設定" isShowBackArrow={false} />
        <div className="min-h-screen bg-#FAF6EF">
          <div className="flex flex-col gap-24px p-16px">
            <div className="flex flex-col gap-12px">
              <div className="flex justify-between">
                <div className="text-14px text-tertiary font-semibold font-hiragino">
                  じぶんの情報
                </div>
                <div className="flex gap-8px">
                  <div className="text-14px text-secondary leading-5 font-hiragino">
                    情報表示
                  </div>
                  <div>
                    <label
                      style={{
                        WebkitTapHighlightColor: "transparent",
                        outline: "none",
                      }}
                      className="inline-flex items-center cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        checked={!isHidden}
                        onChange={() => setIsHidden(!isHidden)}
                        className="sr-only peer"
                      />

                      {/* スイッチの外枠 */}
                      <div
                        className="relative w-37px h-20px bg-lightGray rounded-full transition-all peer-checked:bg-secondary 
                  after:content-[''] after:absolute after:top-[1px] after:start-0.5 after:bg-white 
                  after:border-gray-300 after:border after:rounded-full after:h-18px after:w-18px 
                  after:transition-all peer-checked:after:translate-x-[16px]"
                      ></div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="bg-#FFF rounded-16px">
                <div className="flex justify-between gap-16px py-16px pl-16px pr-8px border-b-solid border-b-1px border-b-lightGray transition-all">
                  <div className="text-nowrap w-32px text-16px text-#111827 font-hiragino leading-6">
                    住所
                  </div>
                  <div
                    className={`whitespace-normal break-all text-14px text-secondary font-hiragino leading-6 ${isHidden ? "bg-#E4E4E7 w-152px rounded-4px h-20px" : ""}`}
                  >
                    {isHidden ? "" : address}
                  </div>
                </div>
                <div className="flex justify-between gap-16px h-24px py-16px pl-16px pr-8px border-b-solid border-b-1px border-b-lightGray transition-all">
                  <div className="text-nowrap text-16px text-#111827 font-hiragino leading-6">
                    性別
                  </div>
                  <div
                    className={`whitespace-normal break-all text-14px text-secondary font-hiragino leading-6 ${isHidden ? "bg-#E4E4E7 w-152px rounded-4px h-20px" : ""}`}
                  >
                    {isHidden ? "" : gender}
                  </div>
                </div>
                <div className="flex justify-between gap-16px h-24px py-16px pl-16px pr-8px transition-all">
                  <div className="text-nowrap text-16px text-#111827 font-hiragino leading-6">
                    生年月日
                  </div>
                  <div
                    className={`whitespace-normal break-all text-14px text-secondary font-hiragino leading-6 ${isHidden ? "bg-#E4E4E7 w-152px rounded-4px h-20px" : ""}`}
                  >
                    {isHidden ? "" : birthday}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-12px">
              <div className="text-14px text-tertiary font-semibold font-hiragino">
                情報提供と権限付与
              </div>
              <div
                onClick={() => openServiceSettings(sdk)}
                className="flex justify-between h-24px py-16px pl-16px pr-8px bg-#FFF rounded-16px "
              >
                <div className="text-16px text-#111827 font-hiragino leading-6">
                  アプリの設定へ
                </div>
                <div>
                  <span className="material-symbols-rounded text-[24px] text-#4B556380 rounded-999 hover:bg-gray-300 hover:cursor-pointer">
                    arrow_outward
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
