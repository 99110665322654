import { useState, useCallback } from 'react';
import { getCurrentGeolocation } from '@pocketsign/in-app-sdk';
import { sdk } from '../lib/sdk';
import { AddressType as ProtoAddressType } from 'schema/gen/es/foodlosscoupon/app/v1/app_pb';
import {
  ADDRESS_TYPE,
  DISTANCE,
  type AddressType,
  type Distance,
} from '../constants';
import { STORAGE_KEY } from '../constants/storage';

interface Location {
  latitude: number;
  longitude: number;
}

export interface LocationWithAddress extends Location {
  address: string;
}

export const useLocationSearch = () => {
  const [selectedAddressType, setSelectedAddressType] = useState<AddressType>(
    () => {
      const saved = sessionStorage.getItem(STORAGE_KEY.SELECTED_ADDRESS_TYPE);
      return (saved as AddressType) || ADDRESS_TYPE.REGISTERED;
    },
  );

  const [selectedDistance, setSelectedDistance] = useState<Distance>(() => {
    const saved = sessionStorage.getItem(STORAGE_KEY.SELECTED_DISTANCE);
    return (saved as Distance) || DISTANCE.ALL;
  });

  const [currentLocation, setCurrentLocation] =
    useState<LocationWithAddress | null>(null);
  const [locationError, setLocationError] = useState<string | null>(null);

  const handleAddressTypeChange = useCallback((type: AddressType) => {
    setSelectedAddressType(type);
    sessionStorage.setItem(STORAGE_KEY.SELECTED_ADDRESS_TYPE, type);
  }, []);

  const handleDistanceChange = useCallback((distance: Distance) => {
    setSelectedDistance(distance);
    sessionStorage.setItem(STORAGE_KEY.SELECTED_DISTANCE, distance);
  }, []);

  const fetchAddressFromCoordinates = useCallback(
    async (location: Location): Promise<string> => {
      try {
        if (!window.google || !window.google.maps) {
          throw new Error('Google Maps APIが読み込まれていません');
        }

        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(
          location.latitude,
          location.longitude,
        );

        return new Promise((resolve) => {
          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK' && results && results[0]) {
              resolve(results[0].formatted_address);
            } else {
              console.error('Geocoding failed:', status);
              resolve('住所を取得できませんでした');
            }
          });
        });
      } catch (error) {
        console.error('Failed to fetch address:', error);
        return '住所を取得できませんでした';
      }
    },
    [],
  );

  const fetchCurrentLocation = useCallback(async () => {
    if (import.meta.env.VITE_P8N_BACKEND_MODE === 'API') {
      // 東京駅を中心に半径約5kmの範囲でランダムな位置を生成
      const tokyoStation = {
        latitude: 35.681236,
        longitude: 139.767125,
      };
      const radius = 0.05; // 約5km
      const randomLocation = {
        latitude: tokyoStation.latitude + (Math.random() - 0.5) * radius,
        longitude: tokyoStation.longitude + (Math.random() - 0.5) * radius,
      };
      const address = await fetchAddressFromCoordinates(randomLocation);
      console.log(address);
      console.log(randomLocation);
      return {
        ...randomLocation,
        address,
      };
    }

    try {
      const result = await getCurrentGeolocation(sdk, {
        enableHighAccuracy: false,
      });
      if (result.result === 'success') {
        setLocationError(null);
        const location = {
          latitude: result.coords.latitude,
          longitude: result.coords.longitude,
        };
        const address = await fetchAddressFromCoordinates(location);
        return {
          ...location,
          address,
        };
      } else {
        return null;
      }
    } catch (error) {
      console.error('Failed to fetch current location:', error);
      return null;
    }
  }, []);

  const getLocationToUse = useCallback(async () => {
    if (selectedAddressType === ADDRESS_TYPE.CURRENT && !currentLocation) {
      const location = await fetchCurrentLocation();
      if (location) {
        setCurrentLocation(location);
        return location;
      } else {
        return null;
      }
    }
    return currentLocation;
  }, [selectedAddressType, currentLocation, fetchCurrentLocation]);

  const updateLocation = useCallback(async () => {
    setCurrentLocation(null);
    const location = await fetchCurrentLocation();
    if (location) {
      setCurrentLocation(location);
    }
  }, [fetchCurrentLocation]);

  const getSearchParams = useCallback(async () => {
    const locationToUse = await getLocationToUse();
    if (selectedAddressType === ADDRESS_TYPE.CURRENT && !locationToUse) {
      setLocationError(
        'アプリに位置情報の使用を許可されていないので利用できません。',
      );
      return null;
    }
    return {
      isAll: selectedDistance === DISTANCE.ALL,
      maxDistance:
        selectedDistance === DISTANCE.ALL
          ? 0
          : Number(selectedDistance.replace('km', '')) * 1000,
      addressType:
        selectedAddressType === ADDRESS_TYPE.CURRENT
          ? ProtoAddressType.CURRENT
          : ProtoAddressType.REGISTERED,
      ...(selectedAddressType === ADDRESS_TYPE.CURRENT && locationToUse
        ? {
            latitude: locationToUse.latitude,
            longitude: locationToUse.longitude,
          }
        : {}),
    };
  }, [selectedAddressType, selectedDistance, getLocationToUse]);

  return {
    selectedAddressType,
    selectedDistance,
    currentLocation,
    locationError,
    setLocationError,
    handleAddressTypeChange,
    handleDistanceChange,
    getSearchParams,
    updateLocation,
    setCurrentLocation,
  };
};
