export const parseError = (error: unknown) => {
  let errorMessage = "不明なエラー";

  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else if (error && typeof error === "object" && "message" in error) {
    errorMessage = String((error as any).message);
  }

  // `/statusCode:` と `/errorMessageDetail:` で分割
  const [title, statusCodePart, detailPart] = errorMessage.split(
    /\/statusCode:|\/errorMessageDetail:/
  );

  return {
    title: title || "不明なエラー", // メインのエラーメッセージ
    statusCode: statusCodePart || "エラーコードなし", // ステータスコード
    detail: detailPart || "詳細なし", // 詳細情報
  };
};
