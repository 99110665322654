import classnames from 'classnames';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type Props = ComponentPropsWithoutRef<'div'> & {
  gap?: number;
  padding?: number;
};

export const Reel = forwardRef<HTMLDivElement, Props>(
  ({ className, gap = 4, padding = 4, style, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classnames('overflow-x-scroll', className)}
        style={{
          ...style,
        }}
        {...props}
      >
        <div
          className="flex shrink-0 *:flex-shrink-0 w-fit"
          style={{
            padding: `${padding * 0.25}rem`,
            gap: `${gap * 0.25}rem`,
          }}
        >
          {children}
        </div>
      </div>
    );
  },
);

Reel.displayName = 'Reel';
