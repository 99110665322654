import { FC, useCallback, useEffect, useState } from 'react';
import Footer from '../organisms/footer';
import { Link } from 'react-router-dom';

import Header from '../organisms/Header';
import Loading from '../organisms/loading';
import { formatDate } from '../../utils/date';
import { PlainMessage, Timestamp } from '@bufbuild/protobuf';
import { GetCouponUsagesResponse } from 'schema/gen/es/foodlosscoupon/app/v1/app_pb';
import { api } from '../../lib/api';
import { parseError } from '../../utils/errors';
import LightLoading from '../organisms/lightLoading';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

const CouponUsageHistoryPage: FC = () => {
  const [couponUsageList, setCouponUsageList] = useState<
    PlainMessage<GetCouponUsagesResponse> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const LIMIT = 20;

  const fetchCouponUsage = useCallback(async (newPageNum: number) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await api.getCouponUsages({
        limit: BigInt(LIMIT),
        offset: BigInt((newPageNum - 1) * LIMIT),
      });

      if (newPageNum === 1) {
        setCouponUsageList({
          couponUsageWithDetails: response.couponUsageWithDetails || [],
        });
      } else if (response?.couponUsageWithDetails.length) {
        setCouponUsageList((prev) => ({
          ...prev,
          couponUsageWithDetails: [
            ...(prev?.couponUsageWithDetails || []),
            ...response.couponUsageWithDetails,
          ],
        }));
      }
      setPageNum(newPageNum);
      setHasMore(response?.couponUsageWithDetails.length === LIMIT);
    } catch (error) {
      console.error('Failed to fetch coupon usage:', error);
      setHasMore(false);
      const { statusCode, detail } = parseError(error);
      alert(
        `クーポン使用履歴の取得に失敗しました\nエラーメッセージ：${detail}\nエラーコード：${statusCode}`,
      );
    } finally {
      setIsLoading(false);
      if (isInitialLoading) setIsInitialLoading(false);
    }
  }, []);

  // 初期ロード
  useEffect(() => {
    if (!isInitialLoading) return;
    fetchCouponUsage(1);
  }, []);

  const { bottomRef } = useInfiniteScroll({
    isLoading,
    hasMore,
    pageNum,
    onLoadMore: fetchCouponUsage,
  });

  if (isInitialLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="利用履歴" isShowBackArrow={false}></Header>
        <div className="pb-65px">
          <div className="flex flex-col gap-8px p-16px pb-40px">
            {couponUsageList?.couponUsageWithDetails.length == 0 ||
            couponUsageList?.couponUsageWithDetails.length == undefined ? (
              <div className="py-48px w-full">
                <div className="flex justify-center ">
                  <span className="material-symbols-rounded text-[80px] text-#9CA3AF80">
                    history
                  </span>
                </div>
                <div className="text-14px text-tertiary text-center leading-5">
                  利用履歴はありません
                </div>
              </div>
            ) : (
              couponUsageList?.couponUsageWithDetails.map(
                (couponUsageWithDetail, index) => {
                  return (
                    <Link
                      key={index}
                      to={`/coupons/${couponUsageWithDetail.coupon?.id}`}
                      ref={
                        index ===
                        couponUsageList.couponUsageWithDetails.length - 1
                          ? bottomRef
                          : null
                      }
                      className="flex gap-12px justify-between px-16px py-12px h-68px no-underline rounded-16px bg-#FFF shadow-DropShadowLow"
                    >
                      <div className="w-68px h-68px">
                        <img
                          src={`${location.origin}/storage/${couponUsageWithDetail?.coupon?.imagePath}`}
                          alt={couponUsageWithDetail.coupon?.couponName}
                          className="w-68px h-68px object-cover rounded-16px"
                          loading="lazy"
                        />
                      </div>
                      <div className="flex flex-col gap-4px">
                        <div className="text-#000 text-16px font-semibold">
                          {couponUsageWithDetail?.coupon?.couponName}
                        </div>
                        <div className="text-#111827 text-14px leading-5">
                          -
                          {couponUsageWithDetail?.coupon?.discountMethod ==
                          'percentage_discount'
                            ? couponUsageWithDetail?.coupon?.discountValue + '%'
                            : couponUsageWithDetail?.coupon?.discountValue +
                              '円'}
                          引きクーポン
                          {Number(
                            couponUsageWithDetail?.couponUsage?.usageCount,
                          )}
                          枚利用
                        </div>
                        <div className="text-secondary text-12px leading-5">
                          {formatDate(
                            (
                              couponUsageWithDetail?.couponUsage
                                ?.createdAt as Timestamp
                            )?.toDate() ?? new Date(0),
                            'yyyy/MM/dd(ww) H:mm',
                          )}
                        </div>
                      </div>
                      <div className="mt-5">
                        <span className="material-symbols-rounded text-[24px] text-#111827">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  );
                },
              )
            )}
          </div>
          {isLoading && (
            <div className="flex justify-center">
              <LightLoading />
            </div>
          )}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CouponUsageHistoryPage;
