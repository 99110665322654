import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AppService } from "schema/gen/es/foodlosscoupon/app/v1/app_connect";
import { withTQueryHelpers } from "./queryUtils";
import { Message, PlainMessage } from "@bufbuild/protobuf";
import { STORAGE_KEY } from "../constants/storage";
const isTokenExpired = (token: string | undefined): boolean => {
  if (!token) return true; // トークンがない場合は期限切れとみなす

  try {
    const payloadBase64 = token.split(".")[1]; // JWTのペイロード部分を取得
    const decodedPayload = JSON.parse(atob(payloadBase64)); // Base64デコードしてJSONパース

    if (!decodedPayload.exp) return true; // `exp` クレームがない場合は期限切れとみなす

    const currentTime = Math.floor(Date.now() / 1000); // 現在のUNIXタイムスタンプ（秒単位）
    return decodedPayload.exp < currentTime; // `exp` が現在の時刻より前なら期限切れ
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return true; // デコードに失敗した場合も期限切れとみなす
  }
};

const transport = createConnectTransport({
  baseUrl: location.origin,
  useBinaryFormat: import.meta.env.PROD,
  useHttpGet: true,
  interceptors: [
    (next) => async (req) => {
      const token = sessionStorage.getItem(STORAGE_KEY.TOKEN)?.replace(/^"|"$/g, "");
      // `CreateAndGetUser` と `GetTenant` はトークンなしでリクエスト
      const allowWithoutToken = ["/CreateOrUpdateUser", "/GetTenant"].some(
        (endpoint) => req.url.includes(endpoint)
      );

      if (!allowWithoutToken) {
        if (isTokenExpired(token)) {
          sessionStorage.removeItem(STORAGE_KEY.TOKEN);
          location.reload();
        }

        req.header.set("Authorization", `Bearer ${token}`);
      }

      return await next(req);
    },
  ],
});

/**
 * persistQueryClientでIDBに保存するときにメソッドなどは消えてしまうので、
 * 予めここでメソッドの部分は型から除いておく
 */
const castResponseAsPlain = <T>(client: T) =>
  client as CastResponseAsPlainObject<T>;
type CastResponseAsPlainObject<T> = {
  [K in keyof T]: CastResponseAsPlain<T[K]>;
};
type CastResponseAsPlain<T> = T extends (
  ...args: infer Args
) => Promise<infer Return extends Message<Return>>
  ? ((...args: Args) => Promise<PlainMessage<Return>>) & {
      [K in keyof T]: T[K];
    }
  : T;

export const api = castResponseAsPlain(
  withTQueryHelpers(
    AppService.typeName,
    createPromiseClient(AppService, transport)
  )
);
