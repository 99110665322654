import { useEffect, useRef } from 'react';

interface UseInfiniteScrollProps {
  isLoading: boolean;
  hasMore: boolean;
  pageNum: number;
  onLoadMore: (nextPage: number) => Promise<void>;
  dependencies?: unknown[];
}

export const useInfiniteScroll = ({
  isLoading,
  hasMore,
  pageNum,
  onLoadMore,
  dependencies = [],
}: UseInfiniteScrollProps) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const bottomRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (isLoading || !hasMore) return;
    if (observer.current) observer.current.disconnect();

    const observerCallback = async (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && !isLoading && hasMore) {
        const nextPage = pageNum + 1;
        await onLoadMore(nextPage);
      }
    };

    observer.current = new IntersectionObserver(observerCallback, {
      threshold: 1.0,
    });

    if (bottomRef.current) observer.current.observe(bottomRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [isLoading, hasMore, pageNum, onLoadMore, ...dependencies]);

  return { bottomRef };
};
