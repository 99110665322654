import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'virtual:uno.css';
import 'normalize.css';
import './styles/tap-highlight.css';

import * as Sentry from '@sentry/react';

import { Client } from './Client';
import SearchCouponByDistancePage from './components/pages/SearchCouponByDistancePage.tsx';
import RecommendPage from './components/pages/RecommendPage.tsx';
import FavoriteCouponPage from './components/pages/FavoriteCouponPage';
import CouponDetailPage from './components/pages/CouponDetailPage';
import TransactionPage from './components/pages/TransactionPage';
import { initializeSDK } from './lib/sdk';
import RequestPermissionLayout from './components/templates/requestPermissionLayout';
import ShopListPage from './components/pages/ShopListPage';
import CreateOrUpdateUserLayout from './components/templates/createOrUpdateUserLayout';
import SettingsPage from './components/pages/SettingsPage';
import ShopDetailPage from './components/pages/ShopDetailPage';
import CouponUsageHistoryPage from './components/pages/CouponUsageHistoryPage';
import Loading from './components/organisms/loading';
import { useJsApiLoader } from '@react-google-maps/api';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RequestPermissionLayout />,
    children: [
      {
        path: '/',
        element: <CreateOrUpdateUserLayout />,
        children: [
          {
            path: '/',
            element: <SearchCouponByDistancePage />,
          },
          {
            path: '/coupons/recommend',
            element: <RecommendPage />,
          },
          {
            path: '/coupons/favorite',
            element: <FavoriteCouponPage />,
          },
          {
            path: '/coupons/:couponID',
            element: <CouponDetailPage />,
          },
          {
            path: '/transactions/:slug',
            element: <TransactionPage />,
          },
          {
            path: '/shops',
            element: <ShopListPage />,
          },
          {
            path: '/shops/:shopID',
            element: <ShopDetailPage />,
          },
          {
            path: '/coupon_usage',
            element: <CouponUsageHistoryPage />,
          },
          {
            path: '/settings',
            element: <SettingsPage />,
          },
        ],
      },
    ],
  },
]);

const baseUrl = import.meta.env.VITE_BACKEND_URL;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  // google maps api の読み込み
  useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
    language: 'ja',
  });

  useEffect(() => {
    const initSDK = async () => {
      await initializeSDK();
      setIsLoading(false);
    };
    initSDK();
  }, []);

  if (isLoading) {
    // ローディング画面を表示
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <Client baseUrl={baseUrl}>
      <RouterProvider router={router} />
    </Client>
  );
};

Sentry.init({
  dsn: 'https://f46e92617a4cf36787b2f48586a6b24c@o4504292631707648.ingest.us.sentry.io/4508415921094656',
  integrations: [],
  environment: import.meta.env.VITE_APP_ENV,
  enabled: import.meta.env.VITE_APP_ENV !== 'local',
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
